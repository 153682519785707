function respondWithCacheAndUpdateCache(
  // @ts-expect-error TS(7006) FIXME: Parameter 'request' implicitly has an 'any' type.
  request,
  // @ts-expect-error TS(7006) FIXME: Parameter 'cachableRequest' implicitly has an 'any... Remove this comment to see the full error message
  cachableRequest,
  // @ts-expect-error TS(7006) FIXME: Parameter 'cachedResponse' implicitly has an 'any'... Remove this comment to see the full error message
  cachedResponse,
  // @ts-expect-error TS(7006) FIXME: Parameter 'resolve' implicitly has an 'any' type.
  resolve,
  // @ts-expect-error TS(7006) FIXME: Parameter 'reject' implicitly has an 'any' type.
  reject,
) {
  caches
    // @ts-expect-error TS(2339) FIXME: Property 'currentCache' does not exist on type 'Wi... Remove this comment to see the full error message
    .open(self.currentCache)
    .then((cache) =>
      fetch(request.clone()).then((response) => {
        if (response.ok) {
          cache.put(cachableRequest, response.clone())
        } else {
          reject()
        }
      }),
    )
    .catch((error) => reject(error))
  resolve(cachedResponse)
}
// @ts-expect-error TS(7006) FIXME: Parameter 'request' implicitly has an 'any' type.
function respondWithFetchAndCache(request, cachableRequest, resolve, reject) {
  return (
    caches
      // @ts-expect-error TS(2339) FIXME: Property 'currentCache' does not exist on type 'Wi... Remove this comment to see the full error message
      .open(self.currentCache)
      .then((cache) =>
        fetch(request)
          .then()
          .then((response) => {
            if (response.ok) {
              cache
                .put(cachableRequest, response.clone())
                .then(() => resolve(response))
            } else {
              reject()
            }
          })
          .catch((error) => reject(error)),
      )
      .catch((error) => reject(error))
  )
}
export const STRATEGIES = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'request' implicitly has an 'any' type.
  cacheFirst(request) {
    return new Promise((resolve, reject) => {
      request
        .clone()
        .json()
        // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
        .then((params) => {
          // @ts-expect-error TS(7034) FIXME: Variable 'cachableRequest' implicitly has type 'an... Remove this comment to see the full error message
          let cachableRequest
          if (request.method === 'POST') {
            // This is a bit hacky but we can't direcly cache POST requests
            const paramsAsQuery = Object.keys(params)
              .map((key) => `${key}=${params[key]}`)
              .join('&')
            cachableRequest = new Request(`${request.url}?${paramsAsQuery}`)
          } else {
            cachableRequest = request.clone()
          }
          caches.match(cachableRequest).then((cachedResponse) => {
            if (cachedResponse) {
              return respondWithCacheAndUpdateCache(
                request,
                // @ts-expect-error TS(7005) FIXME: Variable 'cachableRequest' implicitly has an 'any'... Remove this comment to see the full error message
                cachableRequest,
                cachedResponse,
                resolve,
                reject,
              )
            }
            return respondWithFetchAndCache(
              request,
              // @ts-expect-error TS(7005) FIXME: Variable 'cachableRequest' implicitly has an 'any'... Remove this comment to see the full error message
              cachableRequest,
              resolve,
              reject,
            )
          })
        })
    })
  },
}
// @ts-expect-error TS(7006) FIXME: Parameter 'version' implicitly has an 'any' type.
export function setCacheNamespace(version) {
  if (!version) {
    throw new Error('missing version string')
  }
  // @ts-expect-error TS(2339) FIXME: Property 'currentCache' does not exist on type 'Wi... Remove this comment to see the full error message
  self.currentCache = `RPC-CACHE-${version}`
}
// @ts-expect-error TS(7006) FIXME: Parameter 'request' implicitly has an 'any' type.
export function isInternalRequest(request, origin = self.location.origin) {
  return request.url.startsWith(origin)
}
// @ts-expect-error TS(7006) FIXME: Parameter 'request' implicitly has an 'any' type.
export function isRPCRequest(request, origin = self.location.origin) {
  return isInternalRequest(request, origin) && request.url.endsWith('/rpc')
}
// @ts-expect-error TS(7006) FIXME: Parameter 'handlers' implicitly has an 'any' type.
export function setHandlingStrategies(handlers) {
  // @ts-expect-error TS(2551) FIXME: Property 'handlers' does not exist on type 'Window... Remove this comment to see the full error message
  self.handlers = handlers
}
// @ts-expect-error TS(7006) FIXME: Parameter 'rpcName' implicitly has an 'any' type.
export function getHandlingStrategyFromRpcName(rpcName) {
  // @ts-expect-error TS(2551) FIXME: Property 'handlers' does not exist on type 'Window... Remove this comment to see the full error message
  const handler = self.handlers.find(({ name }) => name === rpcName)
  return handler ? handler.strategy : null
}
// @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
function handleInstall(event) {
  // @ts-expect-error TS(2339) FIXME: Property 'currentCache' does not exist on type 'Wi... Remove this comment to see the full error message
  event.waitUntil(caches.open(self.currentCache).then(self.skipWaiting()))
}
// @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
function handleActivate(event) {
  event.waitUntil(
    caches
      .keys()
      .then((cacheKeys) => {
        const oldCacheKeys = cacheKeys.filter(
          // @ts-expect-error TS(2339) FIXME: Property 'currentCache' does not exist on type 'Wi... Remove this comment to see the full error message
          (key) => !key.includes(self.currentCache),
        )
        const cachesToDelete = oldCacheKeys.map((oldKey) =>
          caches.delete(oldKey),
        )
        return Promise.all(cachesToDelete)
      })
      // @ts-expect-error TS(2339) FIXME: Property 'clients' does not exist on type 'Window ... Remove this comment to see the full error message
      .then(() => self.clients.claim()),
  )
}
// @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
function handleFetch(event) {
  if (!isRPCRequest(event.request)) {
    return null
  }
  event.respondWith(
    event.request
      .clone()
      .json()
      // @ts-expect-error TS(7006) FIXME: Parameter 'req' implicitly has an 'any' type.
      .then((req) => {
        const rpcName = req.name
        const handlingStrategy = getHandlingStrategyFromRpcName(rpcName)
        if (handlingStrategy) {
          return handlingStrategy(event.request.clone()).then(
            // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
            (response) => response,
          )
        }
        return fetch(event.request)
      }),
  )
}
// @ts-expect-error TS(7006) FIXME: Parameter 'version' implicitly has an 'any' type.
function init(version, handlers) {
  setCacheNamespace(version)
  setHandlingStrategies(handlers)
  self.addEventListener('install', handleInstall)
  self.addEventListener('activate', handleActivate)
  self.addEventListener('fetch', handleFetch)
}
export default init
